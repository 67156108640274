<template>
  <!--begin::Pricing-->
  <div>
    <div>
      <Buttons
        :btnCancel="false"
        :textSave="$t('GENERAL.NEW')"
        v-on:save="newLodging"
      />
    </div>
    <v-card>
      <v-card-text>
        <v-row gutters="2" justify="end">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row gutters="2" justify="center">
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="accommodations"
              :search="search"
            >
              <template v-slot:[`item.name`]="{ item }">
                <a
                  @click="getAccommodationData(item.accommodationId)"
                  class="v-application a"
                >
                  <span v-if="item.name !== ''">
                    {{ item.name }}
                  </span>
                  <span v-else>
                    {{ $t("GENERAL.NO_NAME") }}
                  </span>
                </a>
              </template>
              <template v-slot:[`item.accommodationTypeId`]="{ item }">
                <span
                  v-for="AccommodationType in AccommodationTypes"
                  :key="AccommodationType.accommodationTypeId"
                >
                  <span
                    v-if="
                      AccommodationType.accommodationTypeId ===
                        item.accommodationTypeId
                    "
                    >{{ AccommodationType.name }}</span
                  >
                </span>
              </template>
              <template v-slot:[`item.product_rooms`]="{ item }">
                {{
                  totalProductRooms(item.product_rooms, item.accommodationId)
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isShowDialogCampus" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("GENERAL.NOT_CAMPUS") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="goToNewCampus">
            {{ $t("GENERAL.CREATE_CAMPUS") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ALL_ACCOMMODATION } from "@/core/services/store/general/accommodation.module";
import { mapGetters } from "vuex";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    Buttons,
  },
  data() {
    return {
      search: "",
      isShowDialogCampus: false,
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("LODGING.NAME"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("LODGING.TYPE"),
          value: "accommodationTypeId",
          sortable: false,
        },
        {
          text: this.$t("LODGING.CITY"),
          value: "google_place.translation",
          sortable: false,
        },
        {
          text: this.$t("LODGING.ROOMS"),
          value: "product_rooms",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    /**
     * go to new Lodging
     * @method newLodging
     */
    newLodging() {
      this.$router.push({ name: "lodging-new" });
    },

    /**
     * go to new campus
     * @method goToNewCampus
     */
    goToNewCampus() {
      this.$router.push({ name: "campus-new" });
    },
    totalProductRooms(data, accommodationId) {
      const newData = data?.filter(item => {
        if (item.product_basic === null) {
          return false;
        } else {
          return parseInt(item.accommodationId) === parseInt(accommodationId);
        }
      });
      return newData.length;
    },
    getAccommodationData(id) {
      this.$store.dispatch("getAccommodation", id).then(() => {
        this.$router.push({ name: "lodging-edit", params: { id } });
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("LODGING.PAGE") }]);
  },
  created() {
    this.$store.dispatch(GET_ALL_ACCOMMODATION);
    if (this.campuses.length > 0) {
      if (!this.campuses[0].campusId) {
        this.isShowDialogCampus = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "accommodations",
      "campuses",
      "campus",
      "currentUser",
      "company",
      "AccommodationTypes",
    ]),
  },
};
</script>
